var tinymce_callback = tinymce_callback || {};
tinymce.init({
    selector: '.tinymce',
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table contextmenu paste code'
    ],

    toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',

    setup: function (ed) {
        ed.on('init', function(args) {
            $('#page-overlay-loading').hide();
        });
        ed.on('onLoadContent', function(args) {
            $('#page-overlay-loading').show();
        });
        ed.on('Change', function (e) {
            if ( tinymce_callback.Change !== undefined && tinymce_callback.Change.length > 0 ) {
                for ( var i = 0; i < tinymce_callback.Change.length; i++ ) {
                    var callback = tinymce_callback.Change[i];
                    window[callback](e);
                    console.log(callback);
                }
            }
        });
    },
    relative_urls: false,
    convert_urls: false,
    entity_encoding : "raw"
});


$(document).ready(function () {
    $( ".js-sortable" ).sortable({
        revert: true
    });
})


/**
 * Created by andreaszanders on 22.02.16.
 */

function makeOrderList() {

    var $order_list = $('#order-list');

    var order_list = window.order_list;

    if(order_list) {
        for (var i = 0; i < order_list.length; i++) {

            var $list_item = newOrderListItem(order_list[i]);

            $order_list.append($list_item);
        }
    }
}

$('#testconfig-form').submit(function(e) {
    e.preventDefault();

    var new_order_list = [];
    $('.order-list-item').each(function(index){

        if($(this).data('id') != undefined) {

            var new_item = {};

            new_item.id = $(this).data('id');
            new_item.type = $(this).data('type');

            if(new_item.type == 'subtest') {
                new_item.randgroup = $(this).find('.rand-group').val();

                new_item.item_sorting = $(this).find('.item-sorting').val();

                new_item.show_instr = 0;
                if($(this).find('.show-instr').is(':checked')) {
                    new_item.show_instr = 1;
                }

                new_item.show_instr_items = 0;
                if($(this).find('.show-instr-items').is(':checked')) {
                    new_item.show_instr_items = 1;
                }

                new_item.show_end_screen = 0;
                if($(this).find('.show-end-screen').is(':checked')) {
                    new_item.show_end_screen = 1;
                }

            }

            new_order_list[index] = new_item;

        }
    });

    $('#orderlist').val(JSON.stringify(new_order_list));

    this.submit();
});


function makeTestStructure() {

    var $test_structure = $('#test-structure');

    var $dimension = $('<ul class="structure-list dimensions-list"></ul>');

    var structure = window.structure;

    for (var i = 0; i < structure.length; i++) {

        var dim = structure[i];

        var $dim_item = newDimensionItem(i,dim);

        var $subtest = $('<ul class="structure-list subtests-list"></ul>');

        if (dim.subtests.length > 0) {

            for (var m = 0; m < dim.subtests.length; m++) {

                var sub = dim.subtests[m];

                $subtest.append(newSubtestItem(i,m,sub));
            }

        }

        var $subtest_add = $('<button></button>')
            .addClass("js-add-item-button")
            .addClass('add-item-button')
            .data('add',"subtest")
            .data('dimensionid',i)
            .data('subtestid',dim.subtests.length)
            .text('+ Subtest');

        $subtest.append($subtest_add);

        $dim_item.append($subtest);

        $dimension.append($dim_item)
    }

    var $dimension_add = $('<button></button>')
        .addClass("js-add-item-button")
        .addClass('add-item-button')
        .data('add',"dimension")
        .data('dimensionid',structure.length)
        .text('+ Dimension');


    $dimension.append($dimension_add);

    $test_structure.append($dimension);

}


function initButtonBehavior() {

    $('.js-delete-item-button').unbind( "click" ).click(function(){
        var item = $(this).data('delete');
        var type = $(this).data('type');

        if(confirm("Are you sure you wish to delete this " + type + " ?")) {
            $('#'+item).remove();
        }
    });

    $('.js-show-form-button').unbind( "click" ).click(function(e){
        e.preventDefault();
        var show_id = $(this).data('show');
        $('#'+show_id).show('slow');
        $(this).hide();
    });

    $('.js-add-item-button').unbind( "click" ).click(function(e){
        e.preventDefault();

        var add = $(this).data('add');
        var $item = undefined;

        var dimension_id = $(this).data('dimensionid');
        var subtest_id = $(this).data('subtestid');

        if(add == 'dimension') {
            $item = newDimensionItem(dimension_id,{});
            $(this).data('dimensionid',dimension_id + 1)
        } else if(add == 'subtest') {
            $item = newSubtestItem(dimension_id,subtest_id,{});
            $(this).data('subtestid',subtest_id + 1)
        }

        $item.find('legend').text('add');

        $(this).before($item);

        $item.find('.js-form-wrapper').show();

        initButtonBehavior();

    });

    $('.js-action-button').unbind( "click" ).click(function(e) {
        e.preventDefault();

        var dimension_id = $(this).data('dimension');
        var subtest_id = $(this).data('subtest');

        var obj = {};
        var $form_wrapper = undefined;
        var $item = undefined;

        if(dimension_id != undefined) {
            if(subtest_id != undefined) {

                $form_wrapper = $('#form-'+dimension_id+'-'+subtest_id);
                $item = $('#item-'+dimension_id+'-'+subtest_id).find('.subtest-data');

            } else {

                $form_wrapper = $('#form-'+dimension_id);
                $item =  $('#item-'+dimension_id).find('.dimension-data');

            }

            // weight has to be float point. so replace commas with points
            $form_wrapper.find('.weight').val($form_wrapper.find('.weight').val().toString().replace(",","."));

            obj.id = $form_wrapper.find('.select').val();
            obj.name = $form_wrapper.find('.select option:selected').text();
            obj.weight = $form_wrapper.find('.weight').val();
            obj.alias = $form_wrapper.find('.alias').val();
            obj.display = $form_wrapper.find('.display').is( ":checked" ) ? '1' : '0';
            obj.exclude_from_match = $form_wrapper.find('.exclude-from-match').is( ":checked" ) ? '1' : '0';

            updateItemData($item,obj);

            $form_wrapper.hide();
            $item.find('.js-show-form-button').show();
        }


    });


}

function updateItemData($item,data_obj) {

    if($item != undefined) {
        if(data_obj.name != undefined) {
            $item.find('.id').text('[' + data_obj.id + ']');
        }
        if(data_obj.name != undefined) {
            $item.find('.name').text(data_obj.name);
        }
        if(data_obj.alias != undefined) {
            $item.find('.alias').text(data_obj.alias);
        }
        if(data_obj.weight != undefined) {
            $item.find('.weight').text(data_obj.weight);
        }
        if( data_obj.display == '0') {
            $item.find('.display').find('i').removeClass('hide');
        } else if ( ! $item.find('.display').find('i').hasClass('hide') ) {
            $item.find('.display').find('i').addClass('hide');
        }
        if(data_obj.exclude_from_match == '1') {
            $item.find('.exclude-from-match').find('i').removeClass('hide');
        } else if ( ! $item.find('.exclude-from-match').find('i').hasClass('hide') ) {
            $item.find('.exclude-from-match').find('i').addClass('hide');
        }
    }

    return $item;
}


function newOrderListItem(order_list_obj) {

    var item_html = $('#order-list-item-temp').html();
    var subtest_item_html = $('#order-list-subtest-temp').html();
    var $item;

    if(order_list_obj.type == 'subtest') {
        $item = $($.parseHTML(subtest_item_html));
    } else {
        $item = $($.parseHTML(item_html));
    }

    if(order_list_obj.id != undefined) {
        $item.data('id',order_list_obj.id);
        $item.find('.id').text('[' + order_list_obj.id + ']');
    }
    if(order_list_obj.type != undefined) {
        $item.data('type',order_list_obj.type);
        $item.addClass(order_list_obj.type +'-item');
    }
    if(order_list_obj.name != undefined) {
        $item.find('.name').text(order_list_obj.name);
    }
    if(order_list_obj.randgroup != undefined) {
        $item.find('.rand-group option[value="' + order_list_obj.randgroup + '"]').attr('selected', 'selected');
    }
    if(order_list_obj.item_sorting != undefined) {
        $item.find('.item-sorting option[value="' + order_list_obj.item_sorting + '"]').attr('selected', 'selected');
    }
    if(order_list_obj.show_instr == undefined || order_list_obj.show_instr == 1) {
        $item.find('.show-instr').attr('checked', 'checked');
    }
    if(order_list_obj.show_instr_items == undefined || order_list_obj.show_instr_items == 1) {
        $item.find('.show-instr-items').attr('checked', 'checked');
    }
    if(order_list_obj.show_end_screen == undefined || order_list_obj.show_end_screen == 1) {
        $item.find('.show-end-screen').attr('checked', 'checked');
    }
    if(order_list_obj.duration != undefined) {
        $item.find('.duration').text(order_list_obj.duration);
    }

    return $item;
}

function newDimensionItem(dimension_id,dimension_obj) {

    var dim_form_html = $('#form-dimension-temp').html()
        .replace(/DIMENSIONID/gi, dimension_id);

    var $dim_form = $($.parseHTML(dim_form_html));
    if(dimension_obj.id != undefined) {
        $dim_form.find('.select option[value="' + dimension_obj.id + '"]').attr('selected', 'selected');
    }
    if(dimension_obj.weight != undefined) {
        $dim_form.find('.weight').val(dimension_obj.weight);
    }
    if(dimension_obj.alias != undefined) {
        $dim_form.find('.alias').val(dimension_obj.alias);
    }
    if(dimension_obj.sd != undefined) {
        $dim_form.find('.sd').val(dimension_obj.sd);
    }
    if(dimension_obj.alpha != undefined) {
        $dim_form.find('.alpha').val(dimension_obj.alpha);
    }
    if(dimension_obj.display == 0) {
        $dim_form.find('.display').removeAttr('checked')
    }
    $dim_form.find('legend').text('edit dimension');


    var dim_item_html = $('#dimension-item-temp').html()
        .replace(/DIMENSIONID/gi, dimension_id);

    var $dim_item = $($.parseHTML(dim_item_html));
    $dim_item = updateItemData($dim_item,dimension_obj);
    $dim_item.find('.form-edit').append($dim_form);

    return $dim_item;
}


function newSubtestItem(dimension_id,subtest_id,subtest_obj) {

    var sub_form_html =$('#form-subtest-temp').html()
        .replace(/DIMENSIONID/gi, dimension_id)
        .replace(/SUBTESTID/gi, subtest_id);

    var $sub_form = $($.parseHTML(sub_form_html));
    if(subtest_obj.id != undefined) {
        $sub_form.find('.select option[value="' + subtest_obj.id + '"]').attr('selected', 'selected');
    }
    if(subtest_obj.weight != undefined) {
        $sub_form.find('.weight').val(subtest_obj.weight);
    }
    if(subtest_obj.alias != undefined) {
        $sub_form.find('.alias').val(subtest_obj.alias);
    }
    if(subtest_obj.display == 0) {
        $sub_form.find('.display').removeAttr('checked')
    }
    if( subtest_obj.exclude_from_match == 1 ) {
        $sub_form.find('.exclude-from-match').attr('checked',true)
    }
    $sub_form.find('legend').text('edit subtest');


    var sub_item_html = $('#subtest-item-temp').html()
        .replace(/DIMENSIONID/gi, dimension_id)
        .replace(/SUBTESTID/gi, subtest_id)

    var $subtest_item = $($.parseHTML(sub_item_html));
    $subtest_item = updateItemData($subtest_item,subtest_obj);
    $subtest_item.find('.form-edit').append($sub_form);

    return $subtest_item;
}